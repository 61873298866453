import React, { useEffect, useState } from "react";
import classNames from 'classnames';

import HeaderImage from '../../components/imageHeader';

import { graphql } from 'gatsby';
import { useIntl } from "gatsby-plugin-intl";

import ReactMarkdown from 'react-markdown';

import Layout from "../../components/layout";

import '../../styles/index.css';
import styles from '../../styles/consulting.module.css';
import styleser from '../../styles/servico.module.css';

const ConsultoriaBndesPage = ({ data }) => {
    const intl = useIntl();
    const [consulBnds, setConsulBnds] = useState({})

    useEffect(() => {
        data.allStrapiServico.edges.map((item) => {
            if (item.node.tituloPt === "Consultoria BNDES") {
                setConsulBnds(item.node)
            }
        })
    }, [])

    const titulo = intl.locale === 'en' ? consulBnds.tituloEn : consulBnds.tituloPt;
    const texto = intl.locale === 'en' ? consulBnds.textoEn : consulBnds.textoPt;

    return (
        <Layout titlePage={titulo}>

            <HeaderImage title={titulo} />

            <div className={classNames("container", styles.consultingContainer)}>
                <main>

                    <ReactMarkdown
                        className={styleser.boxServicoImg}
                        source={texto}
                        transformImageUri={
                          uri => uri.startsWith('http') ?
                            uri : `${process.env.GATSBY_API_URL}${uri}`
                        }
                    />
                </main>

            </div>
        </Layout>
    )
}

export const query = graphql`
query{
    allStrapiServico {
        edges {
            node {
                tituloPt
                textoPt
                tituloEn
                textoEn
            }
        }
    }
}
`

export default ConsultoriaBndesPage
